import React from "react";
import { Progress, TableProps } from "antd";
import { classNames } from "src/common/classNames";
import { t } from "i18next";
import { ColumnType } from "antd/es/table";
import { viewDate } from "src/common/formats";
import { ZSchedulingRow, ZSchedulingSettings } from "../../SchedulingTypes";
import styles from "./SchedulingTable.module.less";

const buildDefaultProps = (
  key: string,
  title: string,
): ColumnType<ZSchedulingRow> => ({
  key,
  dataIndex: key,
  title,
  className: classNames([styles.cellEllipsis, styles.cellSize]),
});

const buildDefaultDateProps = (
  key: string,
  title: string,
): ColumnType<ZSchedulingRow> => ({
  ...buildDefaultProps(key, title),
  render: (value: string | null) => (value ? viewDate(value) : null),
});

const buildExtraColumn = (
  column: ColumnType<ZSchedulingRow>,
  settingsValue: number | null | undefined,
): ColumnType<ZSchedulingRow>[] => (settingsValue ? [column] : []);

export const buildColumns = (
  rows: ZSchedulingRow[],
  settings: ZSchedulingSettings,
): TableProps["columns"] => [
  {
    ...buildDefaultProps("name", t("Task group / task")),
    className: classNames([
      styles.cellEllipsis,
      styles.cellSize,
      styles.cellName,
    ]),
    fixed: "left",
    render: (_, row: ZSchedulingRow) => (
      <span
        className={classNames([
          [row.type === "PLAN", styles.projectName],
          [
            row.type === "GROUP" ||
              (row.type === "TASK" && !!rows.find(({ id }) => id === row.id)),
            styles.groupName,
          ],
        ])}
      >
        {row.name}
        {row.type === "GROUP" &&
          row.children?.length &&
          `: ${row.children?.length}`}
      </span>
    ),
  },
  {
    ...buildDefaultProps("state", t("State")),
    className: styles.cellSize,
    render: (_, row: ZSchedulingRow) => {
      const { state } = row;
      if (!state) return null;
      const parsed = parseInt(state, 10);
      return Number.isNaN(parsed) ? (
        <div className={styles.cellEllipsis}>{state}</div>
      ) : (
        <Progress percent={parsed} strokeColor="#22BA7A" />
      );
    },
  },
  {
    ...buildDefaultProps("duration", t("Duration")),
  },
  ...buildExtraColumn(
    buildDefaultProps("supplier", t("Supplier")),
    settings.taskSettings?.supplierNameAttributeId,
  ),
  buildDefaultDateProps("plannedStartDate", t("Planned start date")),
  buildDefaultDateProps("plannedEndDate", t("Planned end date")),
  buildDefaultDateProps("actualStartDate", t("Actual start date")),
  buildDefaultDateProps("actualEndDate", t("Actual end date")),
  buildDefaultProps("previousTask", t("Previous task")),
  buildDefaultProps("deltaDays", t("Delay / override")),
];

import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { observer } from "mobx-react-lite";
import { DrawTab } from "./DrawTab";
import { ZSchedSettingsPlan, ZSchedulingSettings } from "../../SchedulingTypes";
import { SchedulingObjType } from "../SchedulingSettingsStore";

interface PropsPlanFields {
  store: FormObjectSectionsStore<SchedulingObjType>;
}

export const PlanFields: React.FC<PropsPlanFields> = observer(({ store }) => (
  <DrawTab>
    <FormObjectSection<
      ZSchedSettingsPlan,
      SchedulingObjType,
      ZSchedulingSettings
    >
      store={store}
      tabKey="planSettings"
      objKey="templateObjectId"
      objType="plan"
      objLabel="Объект для формирования плана"
      title="Список обязательных позиций"
      attrs={[
        {
          attrKey: "nameAttributeId",
          attrLabel: "Укажите атрибут названия плана",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: "groupTaskListAttributeId",
          attrLabel: "Укажите атрибут списка групп задач, включенных в план",
          attrType: AttrTypeName.childEntities,
        },
        {
          attrKey: "startDateAttributeId",
          attrLabel: "Укажите атрибут плановой даты начала",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: "modelLinkAttributeId",
          attrLabel: "Укажите атрибут принадлежности плана к товарной позиции",
          attrType: AttrTypeName.object,
        },
      ]}
    />
  </DrawTab>
));

import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { observer } from "mobx-react-lite";
import { DrawTab } from "./DrawTab";
import { ZSchedSettingsTask, ZSchedulingSettings } from "../../SchedulingTypes";
import { TaskTiesSection } from "./sections";
import { SchedulingObjType } from "../SchedulingSettingsStore";

interface PropsTaskFields {
  store: FormObjectSectionsStore<SchedulingObjType>;
}

export const TaskFields: React.FC<PropsTaskFields> = observer(({ store }) => (
  <DrawTab>
    <FormObjectSection<
      ZSchedSettingsTask,
      SchedulingObjType,
      ZSchedulingSettings
    >
      store={store}
      tabKey="taskSettings"
      objKey="templateObjectId"
      objType="task"
      objLabel="Объект для формирования шаблона задачи"
      title="Список основных позиций"
      attrs={[
        {
          attrKey: "nameAttributeId",
          attrLabel: "Укажите атрибут названия задачи",
          attrType: AttrTypeName.string,
        },
        {
          attrKey: "durationAttributeId",
          attrLabel: "Укажите атрибут длительности задачи",
          attrType: AttrTypeName.int,
        },
        {
          attrKey: "groupAttributeId",
          attrLabel: "Укажите атрибут принадлежности задачи к группе задач",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: "modelLinkAttributeId",
          attrLabel: "Укажите атрибут принадлежности задачи к товарной позиции",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: "relationLinkAttributeId",
          attrLabel: "Укажите атрибут принадлежности задачи к привязкам задачи",
          attrType: AttrTypeName.childEntities,
        },
        {
          attrKey: "supplierRelationAttributeId",
          attrLabel: "Укажите атрибут принадлежности задачи к поставщику",
          attrType: AttrTypeName.object,
          required: false,
        },
      ]}
    />
    <TaskTiesSection objType="taskTies" tabKey="taskSettings" store={store} />
    <FormObjectSection<
      ZSchedSettingsTask,
      SchedulingObjType,
      ZSchedulingSettings
    >
      store={store}
      tabKey="taskSettings"
      objKey="templateObjectId"
      objType="task"
      objLabel="Объект для формирования сроков задачи"
      title="Сроки"
      isObjSelect={false}
      attrs={[
        {
          attrKey: "plannedStartDateAttributeId",
          attrLabel: "Укажите атрибут плановой даты начала задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: "plannedEndDateAttributeId",
          attrLabel: "Укажите атрибут плановой даты окончания задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: "settlementStartDateAttributeId",
          attrLabel: "Укажите атрибут расчетной даты начала задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: "settlementEndDateAttributeId",
          attrLabel: "Укажите атрибут расчетной даты окончания задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: "actualStartDateAttributeId",
          attrLabel: "Укажите атрибут фактической даты начала задачи",
          attrType: AttrTypeName.date,
        },
        {
          attrKey: "actualEndDateAttributeId",
          attrLabel: "Укажите атрибут фактической даты окончания задачи",
          attrType: AttrTypeName.date,
        },
      ]}
    />
    <FormObjectSection<
      ZSchedSettingsTask,
      SchedulingObjType,
      ZSchedulingSettings
    >
      store={store}
      tabKey="taskSettings"
      objKey="supplierObjectId"
      objType="supplier"
      objLabel="Объект для формирования поставщика"
      title="Поставщик"
      required={false}
      attrs={[
        {
          attrKey: "supplierNameAttributeId",
          attrLabel: "Укажите названия поставщика",
          attrType: AttrTypeName.string,
          required: false,
        },
      ]}
    />
  </DrawTab>
));

import React from "react";
import { Flex, Form, Select } from "antd";
import { observer } from "mobx-react-lite";
import { AttrTypeName } from "src/types/AttrType";
import { classNames } from "src/common/classNames";
import { makeFieldOptions } from "src/common/makeFieldOptions";
import styles from "./FormObjectSection.module.less";
import { FormObjectSectionsStore } from "./FormObjectSectionsStore";

interface AttrDef<TFields extends {}> {
  attrKey: keyof TFields;
  attrLabel: string;
  attrType?: AttrTypeName;
  required?: boolean;
  multiple?: boolean;
}

interface PropsFormObjectSection<
  TFields extends {},
  TObjType extends string,
  TForm extends {} = {},
> {
  objKey: keyof TFields;
  objType: TObjType;
  store: FormObjectSectionsStore<TObjType>;
  attrs?: AttrDef<TFields>[];
  tabKey?: keyof TForm; // возможна вложенная структура данных
  objLabel?: string;
  title?: string;
  isObjSelect?: boolean;
  required?: boolean;
}

export const FormObjectSection = observer(
  <TFields extends {}, TObjType extends string, TForm extends {} = {}>({
    store,
    tabKey,
    title,
    objKey,
    objType,
    objLabel,
    attrs,
    isObjSelect = true,
    required = true,
  }: PropsFormObjectSection<TFields, TObjType, TForm>) => {
    const { objects, attrsMap, loadingAttrs } = store;
    const form = Form.useFormInstance();

    const fkey = (name: keyof TFields) => String(name);
    const tkey = (name: keyof TForm) => String(name);
    const fieldName = (name: keyof TFields) =>
      tabKey ? [tkey(tabKey), fkey(name)] : fkey(name);

    const idObj = Form.useWatch(fieldName(objKey));
    React.useEffect(() => {
      if (idObj) store.loadAttrs(objType, idObj);
    }, [idObj]);

    const handleChange = () =>
      attrs?.forEach(({ attrKey }) =>
        form.setFieldValue(fieldName(attrKey), undefined),
      );

    const makeAttrOptions = (attrType?: AttrTypeName) =>
      attrType
        ? store.makeOptionsByType(attrType, attrsMap[objType] ?? [])
        : makeFieldOptions(attrsMap[objType] ?? []);

    return (
      <Flex gap={12} vertical>
        {title && <div className={styles.title}>{title}</div>}
        {isObjSelect && (
          <Form.Item
            name={fieldName(objKey)}
            label={objLabel}
            rules={[{ required }]}
          >
            <Select
              options={makeFieldOptions(objects)}
              showSearch
              optionFilterProp="filterProp"
              allowClear
              onChange={handleChange}
              placeholder="Выбрать"
            />
          </Form.Item>
        )}
        {!!attrs?.length && (
          <div className={classNames([[attrs.length > 1, styles.twoCols]])}>
            {attrs.map(
              ({
                attrKey,
                attrLabel,
                attrType,
                required: attrRequired = true,
                multiple,
              }) => (
                <Form.Item
                  key={fkey(attrKey)}
                  name={fieldName(attrKey)}
                  label={attrLabel}
                  rules={[{ required: attrRequired }]}
                >
                  <Select
                    options={makeAttrOptions(attrType)}
                    showSearch
                    optionFilterProp="filterProp"
                    allowClear
                    loading={loadingAttrs.has(objType)}
                    disabled={!idObj}
                    placeholder="Выбрать"
                    mode={multiple ? "multiple" : undefined}
                  />
                </Form.Item>
              ),
            )}
          </div>
        )}
      </Flex>
    );
  },
);

import * as React from "react";
import { Input, InputProps, InputRef } from "antd";
import { CellBox, FnFocus, PropsCellBox } from "./CellBox";

type InputValue = string | null | undefined;

type PropsCellBoxInput<TValue extends InputValue, Res = void> = Omit<
  PropsCellBox<TValue, Res>,
  "children"
> & {
  inputProps?: Omit<InputProps, "onBlur" | "onChange" | "value">;
};

export const CellBoxInput = <TValue extends InputValue, Res = void>(
  props: PropsCellBoxInput<TValue, Res>,
) => {
  const { inputProps, ...cellProps } = props;
  return (
    <CellBox<TValue, Res> {...cellProps}>
      {(chProps) => (
        <CellBoxInputChildren {...chProps} inputProps={inputProps} />
      )}
    </CellBox>
  );
};
CellBoxInput.defaultProps = {
  inputProps: undefined,
};

type PropsCellBoxInputChildren<TValue extends InputValue> = {
  value: TValue | undefined;
  onChange: (v: TValue) => void;
  onBlur: () => void;
  inputProps: PropsCellBoxInput<TValue>["inputProps"];
  getFocus: (fn: FnFocus) => void;
};

const CellBoxInputChildren = <TValue extends InputValue>(
  props: PropsCellBoxInputChildren<TValue>,
) => {
  const { inputProps, value, onChange, onBlur, getFocus } = props;
  const ref = React.useRef<InputRef>(null);
  React.useEffect(() => {
    getFocus(() => {
      ref.current?.select();
    });
    return () => getFocus(undefined);
  }, [ref.current]);
  return (
    <Input
      ref={ref}
      // variant="borderless"
      {...inputProps}
      value={value as InputProps["value"]}
      onChange={(e) => onChange(e.currentTarget.value as TValue)}
      onBlur={onBlur}
    />
  );
};

import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { observer } from "mobx-react-lite";
import {
  ZSchedSettingsTask,
  ZSchedulingSettings,
} from "../../../SchedulingTypes";
import { SchedulingObjType } from "../../SchedulingSettingsStore";

interface PropsTaskTiesSection {
  tabKey: keyof ZSchedulingSettings;
  objType: SchedulingObjType;
  store: FormObjectSectionsStore<SchedulingObjType>;
}

export const TaskTiesSection: React.FC<PropsTaskTiesSection> = observer(
  ({ tabKey, objType, store }) => (
    <FormObjectSection<
      ZSchedSettingsTask,
      SchedulingObjType,
      ZSchedulingSettings
    >
      store={store}
      tabKey={tabKey}
      objKey="relationObjectId"
      objType={objType}
      objLabel="Объект для формирования зависимости задач"
      title="Привязки"
      attrs={[
        {
          attrKey: "previousAttributeId",
          attrLabel: "Укажите атрибут предыдущей задачи",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: "currentAttributeId",
          attrLabel: "Укажите атрибут текущей задачи",
          attrType: AttrTypeName.object,
        },
        {
          attrKey: "relationTypeAttributeId",
          attrLabel: "Укажите атрибут типа привязки",
          attrType: AttrTypeName.dictSingle,
        },
        {
          attrKey: "deltaDaysAttributeId",
          attrLabel: "Укажите атрибут кол-ва дней задержки/опережения",
          attrType: AttrTypeName.int,
        },
      ]}
    />
  ),
);
